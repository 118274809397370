<template>
  <div
    class="navbar-container d-flex content align-items-center"
    :class="($store.state.model.id && $store.state.model.database) ? 'model-snapshot-workspace' : ''"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-toggler class="d-none d-lg-block mr-50" />

      <div class="content-header-left">
        <div id="global-breadcrumbs" class="breadcrumbs-top d-inline-flex">
          <!-- Breadcrumbs -->
          <div
            class="breadcrumb-wrapper"
          >
            <b-breadcrumb>
              <b-breadcrumb-item v-if="$router.currentRoute.params.modelId" :to="{ name: 'model_dashboard' }">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="(item, index) in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                <a v-if="index !== $route.meta.breadcrumb.length - 1" class="content-header-title text-primary">
                  <!-- Standard Breadcrumb -->
                  {{ item.text }}
                </a>
                <span v-else v-b-tooltip.hover.bottom="$store.state.app.dynamicPageTitle">
                  <!-- Current Page Title -->
                  {{ `${$route.meta.dynamicTitle ?
                    $store.state.app.dynamicPageTitle :
                    $route.meta.pageTitle}` |
                    truncate(70)
                  }}
                </span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
    </div>

    <!--<div-->
    <!--  v-if="this.$store.state.model.id"-->
    <!--  class="d-none d-lg-block mr-3"-->
    <!--&gt;-->
    <!--  <b-row>-->
    <!--    <small class="text-muted">Working on:</small>-->
    <!--  </b-row>-->
    <!--  <b-row>-->
    <!--    <b-dropdown-->
    <!--      variant="flat-primary"-->
    <!--      style="float:right; height: 2rem"-->
    <!--      toggle-class="p-0"-->
    <!--      :text="workingOn"-->
    <!--    >-->
    <!--      <b-dropdown-item @click="updateWorkingOn('All')">-->
    <!--        All-->
    <!--      </b-dropdown-item>-->
    <!--      <b-dropdown-item @click="updateWorkingOn('Specifications')">-->
    <!--        Specifications-->
    <!--      </b-dropdown-item>-->
    <!--      &lt;!&ndash;<b-dropdown-item @click="updateWorkingOn('Issues')">&ndash;&gt;-->
    <!--      &lt;!&ndash;  Issues&ndash;&gt;-->
    <!--      &lt;!&ndash;</b-dropdown-item>&ndash;&gt;-->
    <!--      <b-dropdown-item @click="updateWorkingOn('Releases')">-->
    <!--        Releases-->
    <!--      </b-dropdown-item>-->
    <!--    </b-dropdown>-->
    <!--  </b-row>-->
    <!--</div>-->
    <b-navbar-nav id="notificationsDropDown" class="nav align-items-center ml-auto">
      <!-- Notifications -->
      <notification-dropdown />

      <!-- Logged-in User -->
      <b-nav-item-dropdown
        right
        toggle-class="align-items-center dropdown-user-link ml-1"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="user-nav d-none d-xl-inline-flex">
            <p class="user-name font-weight-bolder mb-0">
              {{ auth.firstName }} {{ auth.lastName }}
            </p>
            <span class="user-status">{{ auth.email }}</span>
          </div>
          <b-avatar
            v-if="avatarBlobUrl"
            size="30"
            variant="light-primary"
            :src="avatarBlobUrl"
          />
          <b-avatar
            v-else
            size="30"
            variant="light-info"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="openUserProfile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Account</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <div v-if="$store.state.model.id && $router.currentRoute.params.modelId" class="d-none d-xl-inline-flex ml-1">
      <div class="d-flex flex-column w-100">
        <span class="text-sm-right font-small-3">Model</span>
        <h5 class="text-primary">
          <router-link :to="{ name: 'model_dashboard' }">
            {{ $store.state.model.name }}
          </router-link>
        </h5>
      </div>
    </div>
    <b-nav-item
      v-if="$store.state.model.id"
      v-b-tooltip.hover.bottom.v-danger="'Close Model'"
      class="d-block ml-1 mt-25"
      @click="closeWorkspace"
    >
      <feather-icon
        size="21"
        icon="XIcon"
        class="text-danger"
      />
    </b-nav-item>

    <!-- Global Modals -->
    <div v-if="$store.state.model.id" id="globalModals">
      <modal-close-model />
    </div>
    <!-- ./Global Modals -->
  </div>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BNavItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ModalCloseModel from '@/components/Models/Modals/CloseModel.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,

    // Model Management modals
    ModalCloseModel,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth
    },
    avatarBlobUrl() {
      return this.$store.state.auth.avatarBlobUrl
    },
    workingOn() {
      return this.$store.state.auth.workingOn
    },
    getWorkingOn() {
      let temp = this.$store.state.auth.workingOn
      if (temp === '') {
        temp = 'All'
        this.$store.dispatch('auth/updateWorkingOn', temp)
        return temp
      }
      return temp
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.$store.dispatch('verticalMenu/navigationWorkspace', null, { root: true })
      },
    },
  },
  async mounted() {
    await this.getUserData()
    await this.assignWorkingOn()
  },
  methods: {
    closeWorkspace() {
      this.$bvModal.show('modal-close-workspace')
    },
    async getUserData() {
      await this.$store.dispatch('auth/getUserData')
    },
    openUserProfile() {
      this.$router
        .push({ name: 'user_profile', params: { userId: this.$store.state.auth.id } })
        .catch(err => { if (err.name !== 'NavigationDuplicated') throw err })
    },
    logout() {
      this.$store.dispatch('auth/logout')
    },
    assignWorkingOn() {
      this.working_on = this.getWorkingOn
    },
    updateWorkingOn(newStatus) {
      this.working_on = newStatus
      this.$store.dispatch('auth/updateWorkingOn', newStatus)
      console.log(this.$store.state.auth.workingOn)
    },
  },
}
</script>

<style lang="scss">
.breadcrumb {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping of breadcrumb items */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  white-space: nowrap; /* Ensure the items don't wrap */
}

.breadcrumb-item {
  display: flex;
  align-items: center; /* Center items vertically */
  max-width: 100%; /* Limit width to parent container */
}

.breadcrumb-item a, .breadcrumb-item span {
  white-space: nowrap; /* Prevent text wrapping inside links and spans */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Use ellipsis for overflow */
  display: block; /* Ensure display respects width and overflow */
  max-width: 100%; /* Ensure max width is respected */
}

/* Style specifically for the last item to allow ellipsis */
.breadcrumb-item.active span {
  max-width: 100%; /* Adjust based on your needs or keep it flexible */
  //
  //@media (max-width: 1919.98px) {
  //  max-width: 80%;
  //}
  //
  //@media (min-width: 1919.98px) {
  //  max-width: 100%;
  //}
}

.btn-flat-primary:hover:not(.disabled):not(:disabled) {
  background-color: transparent;
}

#notificationsDropDown .dropdown-menu {
  min-width: 8rem !important;
  left: -2rem;
}

#notificationsDropDown .dropdown-menu-header {
  height: 5rem;
}
</style>
