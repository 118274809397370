<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-if="totalUnreadNotifications === 0"
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="getNotifications"
      />
      <feather-icon
        v-else
        :badge="totalUnreadNotifications"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="getNotifications"
      />
    </template>
    <!-- Header -->
    <!--<li class="dropdown-menu-header">-->
    <!--  <div class="dropdown-header d-flex">-->
    <!--    <h5 class="notification-title mb-0 mr-auto">-->
    <!--      Notifications-->
    <!--    </h5>-->

    <!--    <b-badge-->
    <!--      pill-->
    <!--      variant="light-success"-->
    <!--    >-->
    <!--      {{ totalUnreadNotifications }} Unread-->
    <!--    </b-badge>-->
    <!--  </div>-->
    <!--</li>-->
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :setting="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Notifications -->
      <b-link
        v-for="notification in $store.state.notifications.notificationsList"
        :key="notification.id"
      >
        <b-media @click="readAndRoute(notification)">
          <b-row>
            <b-col>
              <p class="media-heading">
                <span
                  class="font-small-4"
                  :class="notification.read ? 'font-weight-bold' : 'font-weight-bolder'"
                >
                  {{ notification.header }}
                </span>
                <span
                  class="font-small-1"
                  :class="notification.read ? 'text-secondary' : 'text-success'"
                >
                  {{ notification.created | diffForHumans }}
                </span>
              </p>
              <span class="notification-text">{{ notification.subtitle }}</span>
            </b-col>
            <b-col
              v-if="!notification.read"
              cols="1"
            >
              <font-awesome-icon
                :icon="['fas', 'circle']"
                class="mt-1 mr-1 font-small-2 text-success"
              />
            </b-col>
          </b-row>
        </b-media>
      </b-link>

      <!-- Notification Footer -->
      <li class="dropdown-menu-footer">
        <b-button
          v-if="$store.state.notifications.notificationsList && $store.state.notifications.notificationsList.length > 0"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="gradient-info"
          block
          size="sm"
          @click="markAllAsRead"
        >
          Mark all as read
        </b-button>
        <span v-else>You have no notifications</span>
      </li>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BButton,
  BLink,
  BMedia,
  BNavItemDropdown,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BLink,
    BMedia,
    BNavItemDropdown,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  computed: {
    totalUnreadNotifications() {
      if (this.$store.state.notifications.notificationsList.length > 0) {
        return this.$store.state.notifications.notificationsList.filter(notification => notification.read === false).length
      }
      return 0
    },
  },
  // FIXME - Commented this out until its used properly
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler() {
  //       this.getNotifications()
  //     },
  //   },
  // },
  // mounted() {
  //   this.getNotifications()
  // },
  methods: {
    getNotifications() {
      this.$store.dispatch('notifications/getNotifications')
    },
    readAndRoute(notification) {
      this.$http
        .patch(`/v1/legacy/notifications/${notification.id}`)
        .then(({ data }) => {
          this.$store.commit('notifications/SET_NOTIFICATIONS', data)
          if (this.$route.name !== notification.named_route || this.$route.params.id !== notification.route_params.id) {
            this.$router.push({
              name: notification.named_route,
              params: notification.route_params,
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Navigation not required',
                text: 'Already viewing the selected page.',
                icon: 'TriangleIcon',
                variant: 'info',
              },
            })
          }
        })
        .catch(e => console.error(e))
    },
    markAllAsRead() {
      this.$http
        .put('/v1/legacy/notifications')
        .then(({ data }) => {
          this.$store.commit('notifications/SET_NOTIFICATIONS', data)
        })
        .catch(e => console.error(e))
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollX: true,
    }
    return {
      perfectScrollbarSettings,
    }
  },
}
</script>
