import store from '@/store'
import Vue from 'vue'
import coreService from '@/libs/api-services/core-service'

// axios
// import axios from 'axios'
// import updateToken from '@/auth/middleware/updateToken'
//
// const axiosIns = axios.create({
//   // You can add your headers here
//   // ================================
//   // baseURL: 'https://some-domain.com/api/',
//   // timeout: 1000,
//   // headers: { 'X-Custom-Header': 'foobar' },
// })
//
// if (process.env.NODE_ENV === 'production') {
//   axiosIns.defaults.baseURL = `${window.location.protocol}//api.${window.location.host}`
// }
//
// axiosIns.interceptors.request.use(async config => {
//   const token = await updateToken()
//   config.headers.Authorization = `Bearer ${token}`
//   return config
// })

Vue.prototype.$http = coreService

export default coreService
